import { useState, useEffect, useRef, useCallback } from 'react';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import NavbarOption from './NavbarOption';
import NavbarDisclosureOption from './NavbarDisclosureOption';
import itGleeLogo from '../images/logos/itGleeLogo.png';

const initialNavigation = [
    {
        name: 'About Us', href: '/', current: true, children: [
            { name: 'Services', href: '#services', current: false },
            { name: 'Technologies', href: '#technologies', current: false },
            { name: 'Team', href: "#team", current: false },
            { name: 'Partners', href: "#partners", current: false },
            { name: 'Reach Us', href: "#reachus", current: false }
        ]
    },
    //{ name: 'Projects', href: '/projects', current: false, children: [] },
    { name: 'Contact Us', href: '', current: false, children: [] },
];

export default function Navbar() {
    const [navigation, setNavigation] = useState(initialNavigation);
    const [isSticky, setIsSticky] = useState(false);
    const navbarRef = useRef(null);
    const navbarOriginalTop = useRef(0);
    const location = useLocation();

    const updateCurrent = (parentName, childName = null) => {
        setNavigation(prevNavigation => {
            // Check if the current state actually needs to change
            const newNavigation = prevNavigation.map(item => {
                if (item.name === parentName) {
                    const updatedChildren = item.children.map(child => {
                        return { ...child, current: child.name === childName };
                    });
                    return { ...item, current: true, children: updatedChildren };
                }
                return {
                    ...item,
                    current: false,
                    children: item.children.map(child => ({ ...child, current: false })),
                };
            });

            // Return the new state only if it differs from the previous state
            if (JSON.stringify(newNavigation) !== JSON.stringify(prevNavigation)) {
                return newNavigation;
            }
            return prevNavigation; // No changes
        });
    };

    const scrollToCurrent = useCallback(() => {
        const currentItem = navigation.find(item => item.current);
        if (currentItem) {
            const element = document.getElementById(
                currentItem.children.length > 0
                    ? currentItem.children.find(child => child.current)?.href.replace('#', '')
                    : currentItem.href.replace('#', '')
            );
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [navigation]);

    useEffect(() => {
        const currentPath = location.pathname === '/projects' ? 'Projects' : 'About Us';
        updateCurrent(currentPath);
    }, [location.pathname]);

    useEffect(() => {
        // Only scroll after navigation state has changed
        scrollToCurrent();
    }, [navigation, scrollToCurrent]);

    useEffect(() => {
        const handleScroll = () => {
            if (navbarRef.current) {
                const scrollY = window.scrollY;
                if (navbarOriginalTop.current === 0) {
                    navbarOriginalTop.current = navbarRef.current.getBoundingClientRect().top + scrollY;
                }
                setIsSticky(scrollY > navbarOriginalTop.current);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleComponentScroll = (id) => {
        const element = document.getElementById(id);

        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    return (
        <>
            <div style={{ height: isSticky ? navbarRef.current.getBoundingClientRect().height : 'auto' }} />
            <Disclosure as="nav" className={`bg-zinc-800 transition-all duration-300 ${isSticky ? 'fixed top-0 left-0 w-full z-10' : 'relative'}`} ref={navbarRef}>
                {({ open, close }) => (
                    <>
                        <div className={`mx-auto max-w-7xl px-2 sm:px-6 lg:px-8`}>
                            <div className="relative flex h-24 items-center justify-between">
                                <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                                    <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                        <span className="absolute -inset-0.5" />
                                        <span className="sr-only">Open main menu</span>
                                        <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
                                        <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
                                    </DisclosureButton>
                                </div>
                                <div className="flex flex-1 items-center justify-between sm:items-stretch sm:justify-start">
                                    <button onClick={handleScrollToTop} className="flex flex-shrink-0 items-center sm:ml-0">
                                        <img alt="itGlee" src={itGleeLogo} className="h-14 w-auto" />
                                    </button>
                                </div>
                                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                    <div className="hidden sm:flex space-x-4">
                                        <a href="https://www.linkedin.com/company/itglee/mycompany/" target="_blank" rel="noopener noreferrer" className="text-flamingo hover:text-white">
                                            <FontAwesomeIcon icon={faLinkedin} className="h-7 w-7" />
                                        </a>
                                        <a href="https://www.facebook.com/itglee2015/" target="_blank" rel="noopener noreferrer" className="text-flamingo hover:text-white">
                                            <FontAwesomeIcon icon={faFacebook} className="h-7 w-7" />
                                        </a>
                                    </div>
                                    <div className="hidden sm:ml-6 sm:block">
                                        <div className="h-full items-center flex space-x-4">
                                            {navigation.map((item, index) => (
                                                <NavbarOption key={index} item={item} updateCurrent={updateCurrent} handleScroll={handleComponentScroll} />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <DisclosurePanel className="sm:hidden">
                            <div className="space-y-1 px-2 pb-3 pt-2">
                                {navigation.map((item, index) => (
                                    <NavbarDisclosureOption key={index} item={item} updateCurrent={updateCurrent} handleScroll={handleComponentScroll} close={close}></NavbarDisclosureOption>
                                ))}
                                <div className="flex justify-center space-x-4 pt-2">
                                    <a href="https://www.linkedin.com/company/itglee/mycompany/" target="_blank" rel="noopener noreferrer" className="text-flamingo hover:text-white">
                                        <FontAwesomeIcon icon={faLinkedin} className="h-7 w-7" />
                                    </a>
                                    <a href="https://www.facebook.com/itglee2015/" target="_blank" rel="noopener noreferrer" className="text-flamingo hover:text-white">
                                        <FontAwesomeIcon icon={faFacebook} className="h-7 w-7" />
                                    </a>
                                </div>
                            </div>
                        </DisclosurePanel>
                    </>
                )}
            </Disclosure>
        </>
    );
}
