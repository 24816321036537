import { useEffect } from 'react';
import React, { Suspense } from 'react';

// Use dynamic imports for lazy loading
const Navbar = React.lazy(() => import('../components/Navbar'));
const StartBackground = React.lazy(() => import('../components/StartBackground'));
const TeamMembers = React.lazy(() => import('../components/TeamMembers'));
const Services = React.lazy(() => import('../components/Services'));
const Partners = React.lazy(() => import('../components/Partners'));
const ReachUs = React.lazy(() => import('../components/ReachUs'));
const Technologies = React.lazy(() => import('../components/Technologies'));

function AboutUs() {
  useEffect(() => {
    // Preload all components
    import('../components/Navbar');
    import('../components/StartBackground');
    import('../components/TeamMembers');
    import('../components/Services');
    import('../components/Partners');
    import('../components/ReachUs');
    import('../components/Technologies');
  }, []);

  return (
    <div className="bg-white min-h-screen w-full overflow-x-hidden flex flex-col">
      <Suspense fallback={<div>Loading...</div>}>
        <StartBackground />
        <Navbar />
        <div id="services">
          <Services />
        </div>
        <div id="technologies">
          <Technologies />
        </div>
        <div id="team">
          <TeamMembers />
        </div>
        <div id="partners">
          <Partners />
        </div>
        <div id="reachus">
          <ReachUs />
        </div>
      </Suspense>
    </div>
  );
}

export default AboutUs;
