import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, MenuButton, MenuItems } from '@headlessui/react';
import NavbarOptionMenuItem from './NavbarOptionMenuItem';
import { PhoneIcon } from '@heroicons/react/24/solid';
import ContactUsModal from './ContactUsModal';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function NavbarOption({ item, updateCurrent, handleScroll }) {
    const [isHovered, setIsHovered] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false); // Track menu open state
    const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <>
            {/* If item has no children, render the normal link */}
            {item.children.length === 0 ? (
                <Link
                    to={item.href}
                    key={item.name}
                    aria-current={item.current ? 'page' : undefined}
                    className={classNames(
                        item.current || isHovered ? 'text-white text-lg' : 'text-flamingo text-base hover:text-lg',
                        'rounded-md flex flex-col items-center font-semibold transition-all ease-in-out duration-75 px-4 py-2'
                    )}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={() => {
                        if(item.name === "Contact Us"){
                            openModal();
                        }
                        else {
                            updateCurrent(item.name);
                        }
                        handleScroll(item.href.replace('#', ''));
                    }}
                >
                    {/* For "Contact Us" show the PhoneIcon side by side with the text */}
                    {item.name === "Contact Us" ? (
                        <span className="flex items-center">
                            <PhoneIcon className="h-6 w-6 text-flamingo mr-2" /> {/* Icon and text side by side */}
                            {item.name}
                        </span>
                    ) : (
                        item.name
                    )}
                    {/* Bottom border div with transition for smooth hover effect */}
                    <div
                        className={classNames(
                            item.current || isHovered
                                ? 'bg-flamingo w-full'
                                : 'w-0',
                            'h-1 mt-1 transition-all duration-75 ease-in-out'
                        )}
                    ></div>
                </Link>
            ) : (
                // Dropdown Menu for items with children using Headless UI's Menu component
                <Menu as="div" className="relative ml-3" 
                    onMouseLeave={() => {
                        setIsHovered(false);
                        setIsMenuOpen(false);
                    }}  
                    >
                    <MenuButton
                        onMouseEnter={() => {
                            setIsHovered(true);
                            setIsMenuOpen(true);
                        }}
                    >
                        <Link
                            to={item.href}
                            key={item.name}
                            aria-current={item.current ? 'page' : undefined}
                            className={classNames(
                                item.current || isHovered ? 'text-white text-lg' : 'text-flamingo text-base hover:text-lg',
                                'rounded-md flex flex-col items-center font-semibold transition-all ease-in-out duration-75 px-4 py-2'
                            )}
                            onClick={() => {
                                updateCurrent(item.name);
                                handleScroll(item.href.replace('#', ''));
                                setIsMenuOpen(!isMenuOpen);
                            }}
                        >
                            {item.name}
                            {/* Bottom border div with transition for smooth hover effect */}
                            <div
                                className={classNames(
                                    item.current || isHovered
                                        ? 'bg-flamingo w-full'
                                        : 'w-0',
                                    'h-1 mt-1 transition-all duration-200 ease-in-out'
                                )}
                            ></div>
                        </Link>
                    </MenuButton>

                    {/* MenuItems with transition for showing/hiding on hover */}
                    <MenuItems
                        className={classNames(
                            isMenuOpen ? 'opacity-100 scale-100' : 'hidden',
                            'absolute left-1/2 transform -translate-x-1/2 z-10 pt-1 w-36 origin-top rounded-md bg-zinc-700 py-1 ring-1 ring-black ring-opacity-5 transition-all duration-75 ease-in-out'
                        )}
                        static
                        onMouseLeave={() => setIsMenuOpen(false)} // Close menu when not hovering
                    >
                        {item.children.map((childItem, index) => (
                            <NavbarOptionMenuItem key={index} item={childItem} parentName={item.name} updateCurrent={updateCurrent} handleScroll={handleScroll} parentHref={item.href} />
                        ))}
                    </MenuItems>
                </Menu>
            )}

            {/* Contact Us Modal */}
            <ContactUsModal isOpen={isModalOpen} onRequestClose={closeModal} />
        </>
    );
}
