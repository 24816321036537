import { useState } from 'react';
import { MenuItem } from '@headlessui/react';
import { Link } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function NavbarOptionMenuItem({ item, parentName, updateCurrent, handleScroll, parentHref }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <MenuItem>
      <Link
        to={parentHref}
        key={item.name}
        aria-current={item.current ? 'page' : undefined}
        className={classNames(
          item.current || isHovered ? 'text-white text-lg' : 'text-flamingo text-base hover:text-lg',
          'rounded-md flex flex-col items-center font-semibold transition-all ease-in-out duration-75 px-4 py-2'
      )}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
          updateCurrent(parentName, item.name);
          setTimeout(() => { handleScroll(item.href.replace('#', '')); }, 100); // Scroll after closing menu
      }}
      >
        {item.name}
        {/* Bottom border div with transition for smooth hover effect */}
        <div
          className={classNames(
            (item.current || isHovered
              ? 'bg-flamingo w-3/4'
              : 'w-0'),
            'h-1 mt-1 transition-all duration-50 ease-in-out'
          )}
        ></div>
      </Link>
    </MenuItem>
  );
}
