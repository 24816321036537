import './App.css';
import AboutUs from './pages/AboutUs';
import Projects from './pages/Projects'; 
import QrCodePage from './pages/QrCodePage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<AboutUs />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/rsqrcode" element={<QrCodePage />} />
      </Routes>
    </Router>
  );
}

export default App;
