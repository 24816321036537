import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { XMarkIcon } from '@heroicons/react/24/outline';
import emailjs from '@emailjs/browser';

Modal.setAppElement('#root'); // Ensure this targets the correct root element

const ContactUsModal = ({ isOpen, onRequestClose }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});
    const [statusMessage, setStatusMessage] = useState('');

    useEffect(() => {
        if (isOpen) {
            setIsVisible(true); // Start the animation when modal opens
        } else {
            // Delay hiding modal for the fade-out effect
            setTimeout(() => setIsVisible(false), 300);
        }
    }, [isOpen]);

    const validateForm = () => {
        const newErrors = {};
        if (!name) newErrors.name = 'Name is required';
        if (!email) newErrors.email = 'Email is required';
        if (!message) newErrors.message = 'Message is required';
        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        // EmailJS integration
        const serviceId = 'service_xpwlikl';
        const templateId = 'template_dc3szqk';
        const userId = 'ztolT2YZvVXrCvE-w';

        const templateParams = {
            from_name: name,
            from_email: email,
            message: message,
        };

        emailjs.send(serviceId, templateId, templateParams, userId)
            .then(() => {
                setStatusMessage('Message sent successfully!');
                setName('');
                setEmail('');
                setMessage('');
                setErrors({});
                onRequestClose();
            })
            .catch(() => {
                setStatusMessage('Failed to send message. Please try again later.');
            });
    };

    const closeModal = () => {
        setTimeout(() => {
            onRequestClose(); // Call parent's close function after animation ends
        }, 300);
    };

    return (
        isVisible && (
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                className={`modal-container w-full max-w-md sm:max-w-lg mx-auto bg-flamingo p-6 rounded-lg shadow-lg z-50 transition-transform transform ${isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95'} duration-500 ease-in-out`}
                overlayClassName={`fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50 transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0'}`}
                shouldCloseOnOverlayClick={true}
                closeTimeoutMS={300}
            >
                <div className="flex justify-between items-center">
                    <h2 className="text-2xl font-bold text-white">Contact Us</h2>
                    <button onClick={closeModal} aria-label="Close">
                        <XMarkIcon className="h-6 w-6 text-white" />
                    </button>
                </div>
                <form onSubmit={handleSubmit} className="mt-4">
                    <div className="mb-4">
                        <label className="block mb-2 text-white" htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className={`border p-2 w-full bg-white rounded ${errors.name ? 'border-red-500' : 'border-gray-300'}`}
                            placeholder="Enter your name"
                        />
                        {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
                    </div>
                    <div className="mb-4">
                        <label className="block mb-2 text-white" htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className={`border p-2 w-full bg-white rounded ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
                            placeholder="Enter your email"
                        />
                        {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                    </div>
                    <div className="mb-4">
                        <label className="block mb-2 text-white" htmlFor="message">Message</label>
                        <textarea
                            id="message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            className={`border p-2 w-full bg-white rounded ${errors.message ? 'border-red-500' : 'border-gray-300'}`}
                            rows="4"
                            placeholder="Your message"
                        />
                        {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}
                    </div>
                    <div className="flex justify-center">
                        <button type="submit" className="bg-white text-flamingo font-semibold py-2 px-4 rounded-lg hover:bg-gray-200">
                            Submit
                        </button>
                    </div>
                    {statusMessage && <p className="text-white text-center mt-4">{statusMessage}</p>}
                </form>
            </Modal>
        )
    );
};

export default ContactUsModal;
