import { useState } from 'react';
import { DisclosureButton, Disclosure, DisclosurePanel } from '@headlessui/react';
import { PhoneIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import NavbarDisclosureOptionChild from './NavbarDisclosureOptionChild';
import ContactUsModal from './ContactUsModal';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function NavbarDisclosureOption({ item, updateCurrent, handleScroll, close }) {
    const [isHovered, setIsHovered] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => {
        setIsModalOpen(false);
        close();
    }

    return (
        <div>
            {/* Handle items without children */}
            {item.children.length === 0 ? (
                <Link
                    to={item.href}
                    aria-current={item.current ? 'page' : undefined}
                    className={classNames(
                        item.current
                            ? 'text-white'
                            : 'text-flamingo hover:text-white',
                        'block rounded-md px-3 py-2 text-base font-medium'
                    )}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={() => {
                        if (item.name === "Contact Us") {
                            openModal();
                        }
                        else {
                            updateCurrent(item.name);
                            close();  // Close the panel first
                        }
                        setTimeout(() => { handleScroll(item.href.replace('#', '')); }, 200); // Scroll after closing menu
                    }}
                >
                    {/* For "Contact Us" show the PhoneIcon side by side with the text */}
                    {item.name === "Contact Us" ? (
                        <span className="flex items-center">
                            <PhoneIcon className="h-6 w-6 text-flamingo mr-2" /> {/* Icon and text side by side */}
                            {item.name}
                        </span>
                    ) : (
                        item.name
                    )}
                    {/* Bottom border div with transition for smooth hover effect */}
                    <div
                        className={classNames(
                            item.current || isHovered
                                ? 'bg-flamingo w-full'
                                : 'w-0',
                            'h-1 mt-1 transition-all duration-75 ease-in-out'
                        )}
                    ></div>
                </Link>
            ) : (
                // Handle items with children (submenu)
                <Disclosure as="div">
                    {({ open }) => (
                        <>
                            <DisclosureButton
                                as="a"
                                aria-current={item.current ? 'page' : undefined}
                                className={classNames(
                                    item.current
                                        ? 'text-white'
                                        : 'text-flamingo hover:text-white',
                                    'block rounded-md px-3 py-2 text-base font-medium'
                                )}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                onClick={() => {
                                    updateCurrent(item.name);
                                    handleScroll(item.href.replace('#', ''));
                                }}
                            >
                                {/* For "Contact Us" show the PhoneIcon side by side with the text */}
                                {item.name === "Contact Us" ? (
                                    <span className="flex items-center">
                                        <PhoneIcon className="h-6 w-6 text-flamingo mr-2" /> {/* Icon and text side by side */}
                                        {item.name}
                                    </span>
                                ) : (
                                    item.name
                                )}
                                {/* Bottom border div with transition for smooth hover effect */}
                                <div
                                    className={classNames(
                                        item.current || isHovered
                                            ? 'bg-flamingo w-full'
                                            : 'w-0',
                                        'h-1 mt-1 transition-all duration-75 ease-in-out'
                                    )}
                                ></div>
                            </DisclosureButton>
                            <DisclosurePanel className="pl-4 space-y-1">
                                {item.children.map((childItem, index) => (
                                    <NavbarDisclosureOptionChild key={index} item={childItem} parentName={item.name} updateCurrent={updateCurrent} handleScroll={handleScroll} parentHref={item.href} close={close}></NavbarDisclosureOptionChild>
                                ))}
                            </DisclosurePanel>
                        </>
                    )}
                </Disclosure>
            )}
            {/* Contact Us Modal */}
            <ContactUsModal isOpen={isModalOpen} onRequestClose={closeModal} />
        </div>
    );
}
