import React from 'react';
import QrCode from '../images/qrcode/itGleeContactQRCode.png'

const QrCodePage = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <img
        src={QrCode}
        alt="QR Code"
        className='w-64 h-80'
      />
    </div>
  );
};

export default QrCodePage;
