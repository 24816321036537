import { useState } from 'react';
import { Link } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function NavbarDisclosureOptionChild({ item, parentName, updateCurrent, handleScroll, close, parentHref }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}  // Use onMouseOver
      onMouseLeave={() => setIsHovered(false)}  // Use onMouseOut
      className="block rounded-md px-4 py-2"
    >
      <Link
        to={parentHref}
        key={item.name}
        aria-current={item.current ? 'page' : undefined}
        className={classNames(
          item.current || isHovered ? 'text-white text-lg' : 'text-flamingo text-base hover:text-lg',
          'font-semibold transition-all ease-in-out duration-75'
        )}
        onClick={() => {
          updateCurrent(parentName, item.name);
          close();  // Close the panel first
          setTimeout(() => { handleScroll(item.href.replace('#', '')); }, 200); // Scroll after closing menu
        }}
      >
        {item.name}
      </Link>
      {/* Bottom border div with transition for smooth hover effect */}
      <div
        className={classNames(
          item.current || isHovered ? 'bg-flamingo w-3/4' : 'w-0',
          'h-1 mt-1 transition-all duration-150 ease-in-out'
        )}
      ></div>
    </div>
  );
}
